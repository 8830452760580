export const HomePageExplore = [
    {
        tag: 'Free',
        courses : [
            {
                heading : "Learn HTML",
                description : "This course covers the basic concepts of HTML including creating and structuring web pages, adding text, links, images, and more.",
                level : 'Beginner',
                lessionNumber : 6
            },
            {
                heading : "Learn CSS",
                description : "This course explores advanced topics in HTML5 and CSS3, including animations, transitions, and layout techniques",
                level : 'Beginner',
                lessionNumber : 6
            },
            {
                heading : "Responsive Web design",
                description : "This course teaches responsive web design techniques, allowing web pages to adapt to different devices and screen sizes",
                level : 'Beginner',
                lessionNumber : 6
            },
        ]
    },
    {
        tag: 'New to coding',
        courses : [
            {
                heading : "Excel",
                description : "This course covers the basic concepts of HTML including creating and structuring web pages, adding text, links, images, and more.",
                level : 'Beginner',
                lessionNumber : 6
            },
            {
                heading : "Six Sigma",
                description : "This course explores advanced topics in HTML5 and CSS3, including animations, transitions, and layout techniques",
                level : 'Beginner',
                lessionNumber : 6
            },
            {
                heading : "Analytics ",
                description : "This course teaches responsive web design techniques, allowing web pages to adapt to different devices and screen sizes",
                level : 'Beginner',
                lessionNumber : 6
            },
        ]
    },
    {
        tag: 'Most popular',
        courses : [
            {
                heading : "6-Month-Certificate-Course",
                description : "This course covers the basic concepts of HTML including creating and structuring web pages, adding text, links, images, and more.",
                level : 'Beginner',
                lessionNumber : 6
            },
            {
                heading : "4-Month-Certificate-Course",
                description : "This course explores advanced topics in HTML5 and CSS3, including animations, transitions, and layout techniques",
                level : 'Beginner',
                lessionNumber : 6
            },
            {
                heading : "3-Month-Certificate-Course",
                description : "This course teaches responsive web design techniques, allowing web pages to adapt to different devices and screen sizes",
                level : 'Beginner',
                lessionNumber : 6
            },
        ]
    },
    {
        tag: 'Skills paths',
        courses : [
            {
                heading : "Functional Analytics",
                description : "This course covers the basic concepts of HTML including creating and structuring web pages, adding text, links, images, and more.",
                level : 'Beginner',
                lessionNumber : 6
            },
            {
                heading : "Project Management",
                description : "This course explores advanced topics in HTML5 and CSS3, including animations, transitions, and layout techniques",
                level : 'Beginner',
                lessionNumber : 6
            },
            {
                heading : "Certificate-Course",
                description : "This course teaches responsive web design techniques, allowing web pages to adapt to different devices and screen sizes",
                level : 'Beginner',
                lessionNumber : 6
            },
        ]
    },
    {
        tag: 'Career paths',
        courses : [
            {
                heading : "Excel",
                description : "This course covers the basic concepts of HTML including creating and structuring web pages, adding text, links, images, and more.",
                level : 'Beginner',
                lessionNumber : 6
            },
            {
                heading : "Advanced",
                description : "This course explores advanced topics in HTML5 and CSS3, including animations, transitions, and layout techniques",
                level : 'Beginner',
                lessionNumber : 6
            },
            {
                heading : "3SV Courses",
                description : "This course teaches responsive web design techniques, allowing web pages to adapt to different devices and screen sizes",
                level : 'Beginner',
                lessionNumber : 6
            },
        ]
    },
]
export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About Us",
    path: "/about",
  },
  {
    title: "Course",
    // path: '/catalog',
  },
   
  {
    title: "Faculty",
    path: "/faculty",
  }, 
  {
    title: "Services",
   // path: "/services",
  }, 
  {
    title: "Gallery",
    path: "/gallery",
  },
  
  {
    title: "Contact Us",
    path: "/contact",
  },
   
];
